@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-body text-paragraph leading-6 bg-gray-200;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-white;
    }
    .section {
        @apply py-12 lg:py-32;
    }
    .section-title {
        @apply text-3xl lg:text-4xl font-medium lg:font-extrabold mb-5;
    }
    .subtitle {
        @apply text-base text-paragraph mb-16 lg:mb-24 max-w-[520px];
    }
    .btn {
        @apply py-4 px-7 font-medium text-white flex items-center justify-center rounded-sm hover:bg-accent-hover transition-all;
    }
    .btn-lg {
        @apply h-[54px];
    }
    .btn-md {
        @apply h-[48px];
    }
    .input {
        @apply bg-secondary text-paragraph h-[60px] outline-none pl-6 w-full font-body text-[15px] rounded-sm focus:outline focus:outline-1 focus:outline-accent;
    }
    .textarea {
        @apply bg-secondary resize-none w-full outline-none p-6 rounded-sm h-[200px] focus:outline focus:outline-1 focus:outline-accent;
    }
    .active {
        @apply text-accent;
    }

    .text-white {
        color: white !important;
    }

    .text-tyc {
        color: #009999 !important;
    }

    .text-sec-tyc {
        color: #ffcc00 !important;
    }

    .bg-tyc {
        background-color: #009999 !important;
    }

    .bg-sec-tyc {
        background-color: #ffcc00 !important;
        color: white !important;
    }

    .bg-whatsapp{
        background-color: #25d366 !important;
        color: white !important;    
    }

    .fixed-right-button {
        background-color: #25d366;
        width: 60px !important;
        height: 60px !important;
        position: fixed;
        right: 0px;
        border-radius: 10px 0px 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .fixed-right-button:hover {
        background-color: #14b950;
    }

    .whatsapp-image {
        width: 40px !important;
        height: 40px !important;
    }

    * {
        font-family: "Montserrat";
    }
}
